// src/utils/urlUtils.js

const validGridSizes = [1, 2, 4, 6, 9, 12];

export const generateUrl = (selectedStreams, gridSize, autoReplace) => {
    const usernames = selectedStreams
      .filter(stream => stream && stream.id)
      .map(stream => stream.id)
      .join(',');
    
    const params = new URLSearchParams();
    
    if (validGridSizes.includes(gridSize) && gridSize !== 4) {
      params.append('grid', gridSize);
    }
    
    if (autoReplace !== '0') {
      params.append('auto_replace', autoReplace);
    }
    
    const queryString = params.toString();
  
    if (usernames || queryString) {
      return `/view${usernames ? `/${usernames}` : ''}${queryString ? `?${queryString}` : ''}`;
    }
    
    return '/';
  };
  
export const parseUrl = (pathname, search) => {
  const parts = pathname.split('/').filter(Boolean);
  let usernames = [];
  if (parts.length > 1 && parts[0] === 'view') {
    usernames = parts[1].split(',').filter(Boolean);
  }
  const params = new URLSearchParams(search);
  
  const gridSize = parseInt(params.get('grid')) || 4;
  
  return {
    usernames: usernames,
    gridSize: validGridSizes.includes(gridSize) ? gridSize : 4,
    autoReplace: params.get('auto_replace') || '0'
  };
};
