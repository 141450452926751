import React, { useState, useRef, useEffect } from 'react';
import { FaTimes, FaExpand, FaCompress } from 'react-icons/fa';
import { Shuffle } from 'lucide-react';

const StreamPlayer = ({ stream, onClose, isDragging, onRandomClick }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const containerRef = useRef(null);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) {
        containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  if (!stream) return null;

  return (
    <div 
      ref={containerRef} 
      className="relative w-full h-full bg-black overflow-hidden flex flex-col"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {isFullScreen && (
        <div className="bg-black bg-opacity-75 text-white p-2 z-20">
          <span>{stream.title}</span>
        </div>
      )}
      <div className="relative flex-grow">
        <iframe
          src={stream.embedUrl}
          className="absolute inset-0 w-full h-full"
          allow="autoplay; encrypted-media; picture-in-picture"
          allowFullScreen
          title={`Stream: ${stream.title}`}
          style={{ pointerEvents: isDragging ? 'none' : 'auto' }}
        />
        {(isHovering || isFullScreen) && (
          <>
            {/* Random button - appears at top center */}
            <div className="absolute top-3 left-1/2 transform -translate-x-1/2 transition-opacity duration-300 opacity-100 z-30">
              <button 
                onClick={onRandomClick}
                className="flex items-center justify-center p-2 bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white rounded-full shadow-lg shadow-purple-500/30 transition-all duration-200"
              >
                <Shuffle size={16} />
              </button>
            </div>
            
            <div className="absolute top-2 right-2 transition-opacity duration-300 opacity-100 z-30">
              <button 
                onClick={onClose} 
                className="p-2 bg-black bg-opacity-50 text-white rounded hover:bg-opacity-75 transition-colors duration-200"
              >
                <FaTimes />
              </button>
            </div>
            <div className="absolute bottom-2 right-2 transition-opacity duration-300 opacity-100 z-30">
              <button 
                onClick={toggleFullScreen} 
                className="p-2 bg-black bg-opacity-50 text-white rounded hover:bg-opacity-75 transition-colors duration-200"
              >
                {isFullScreen ? <FaCompress /> : <FaExpand />}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StreamPlayer;
