import axios from 'axios';

const fetchStreamsFromJsonApi = async (apiUrl) => {
  try {
    const response = await axios.get(apiUrl);
    return mapStreamsData(response.data.results);
  } catch (error) {
    console.error('Error fetching streams from JSON API:', error);
    return [];
  }
};

const mapStreamsData = (data) => {
  return data.map(stream => ({
    id: stream.id || stream.username || `stream-${Math.random().toString(36).substr(2, 9)}`,
    title: stream.title || stream.display_name || 'Untitled Stream',
    thumbnail: stream.thumbnail || stream.image_url || 'https://via.placeholder.com/480x360.png?text=No+Thumbnail',
    embedUrl: generateEmbedUrl(stream.username),
    tags: stream.tags || [],
    roomSubject: stream.room_subject || '',
    gender: mapGender(stream.gender),
    age: stream.age || null,
    numUsers: stream.num_users || 0,
    numFollowers: stream.num_followers || 0,
    tokensLeft: parseTokensLeft(stream.room_subject)
  }));
};

const mapGender = (gender) => {
  const genderMap = {
    'f': 'Women',
    'm': 'Men',
    'c': 'Couples',
    't': 'Trans'
  };
  return genderMap[gender] || 'Other';
};

const parseTokensLeft = (roomSubject) => {
  if (!roomSubject) return null;
  
  const match = roomSubject.match(/\[(\d+)\s*tokens\s*(remaining|left)\]/i);
  if (match) {
    return parseInt(match[1], 10);
  }
  
  if (roomSubject.includes('[Goal reached!]')) {
    return 0;
  }
  
  return null;
};

const generateEmbedUrl = (username) => {
  return `https://chaturbate.com/in/?tour=hdfU&campaign=FCuis&track=multiview&signup_notice=0&b=${encodeURIComponent(username)}&disable_sound=1&mobileRedirect=never`;
};

export { fetchStreamsFromJsonApi };