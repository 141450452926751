import { useState, useEffect, useRef, useCallback } from 'react';
import { fetchStreamsFromJsonApi } from '../services/jsonApiService';

const API_BASE_URL = 'https://chaturbate.com/api/public/affiliates/onlinerooms/';
const WM_PARAM = 'wm=FCuis'; // Assuming this is constant
const LIMIT = 200; // Fetch more results per call

const useStreams = (filters = {}, refreshInterval = 60000) => {
  const [availableStreams, setAvailableStreams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true); // Assume more initially
  const intervalRef = useRef(null);
  const currentFiltersRef = useRef(filters); // Ref to track filters for interval

  // Update ref when filters change
  useEffect(() => {
    currentFiltersRef.current = filters;
  }, [filters]);

  const fetchData = useCallback(async (isLoadMore = false) => {
    const currentOffset = isLoadMore ? offset : 0;
    if (isLoadMore && isLoadingMore) return; // Prevent multiple load more calls
    if (!isLoadMore) {
      setIsLoading(true);
    } else {
      setIsLoadingMore(true);
    }

    // Construct URL with filters
    const params = new URLSearchParams();
    params.append('wm', WM_PARAM.split('=')[1]); // Add wm param
    params.append('client_ip', 'request_ip'); // Add client_ip
    params.append('limit', LIMIT.toString());
    params.append('offset', currentOffset.toString());

    // Add filters from the filters object
    if (filters.gender) params.append('gender', filters.gender);
    if (filters.region) params.append('region', filters.region);
    if (filters.hd !== undefined) params.append('hd', filters.hd.toString());
    if (filters.exhibitionist !== undefined) params.append('exhibitionist', filters.exhibitionist.toString());
    // Note: API docs show multiple query params for gender/region/tag, 
    // but URLSearchParams typically handles single values. 
    // If multiple values are needed, the API service might need adjustment.
    // For now, assuming single value filters based on current UI structure.

    const fullUrl = `${API_BASE_URL}?${params.toString()}`;
    console.log(`Fetching streams from API: ${fullUrl} (Load More: ${isLoadMore})`);

    try {
      // Assuming fetchStreamsFromJsonApi now just takes the full URL
      const newStreams = await fetchStreamsFromJsonApi(fullUrl); 
      
      setAvailableStreams(prevStreams => isLoadMore ? [...prevStreams, ...newStreams] : newStreams);
      setOffset(currentOffset + newStreams.length);
      setHasMore(newStreams.length === LIMIT); // If we got less than limit, assume no more pages
      setLastUpdated(new Date());
      console.log(`Fetched ${newStreams.length} streams. Total: ${isLoadMore ? availableStreams.length + newStreams.length : newStreams.length}. Has More: ${newStreams.length === LIMIT}`);

    } catch (error) {
      console.error('Error fetching streams:', error);
      setHasMore(false); // Stop trying if error occurs
    } finally {
      if (!isLoadMore) {
        setIsLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  }, [offset, isLoadingMore, filters, availableStreams.length]); // Include filters and length in dependencies

  const refreshStreams = useCallback(() => {
    fetchData(false);
  }, [fetchData]);

  const loadMoreStreams = useCallback(() => {
    if (hasMore && !isLoadingMore) {
      fetchData(true);
    }
  }, [hasMore, isLoadingMore, fetchData]);

  // Effect for initial load and filter changes
  useEffect(() => {
    console.log('Filters changed or initial load, fetching data...');
    fetchData(false); // Fetch initial data or refresh when filters change
    // No interval setup here anymore, interval effect is separate
  }, [filters, fetchData]); // Also depend on fetchData here

  // Stable callback for the interval, depends on the stable refreshStreams
  const refreshCallbackForInterval = useCallback(() => {
     console.log('Interval refresh triggered...');
     // refreshStreams itself depends on fetchData, ensuring the latest is called
     refreshStreams(); 
  }, [refreshStreams]);

  // Effect for interval refresh
  useEffect(() => {
    // Clear existing interval if refreshInterval changes
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    
    // Set up new interval using the stable callback
    intervalRef.current = setInterval(refreshCallbackForInterval, refreshInterval);
    
    // Clean up interval on unmount or when refreshInterval changes
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [refreshInterval, refreshCallbackForInterval]); // Depend only on interval and the stable callback

  return { 
    availableStreams,
    isLoading,
    isLoadingMore,
    lastUpdated,
    hasMore,
    refreshStreams,
    loadMoreStreams 
  };
};

export default useStreams;
