import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GridCell from './GridCell';
import ThumbnailGrid from './ThumbnailGrid';
import HeaderBar from './HeaderBar';
import useStreams from '../../hooks/useStreams';
import { getGridClassName, getMaxGridSize } from '../../utils/gridUtils';
import { generateUrl, parseUrl } from '../../utils/urlUtils';
import { useMobileDetection } from '../../hooks/useMediaQuery';
import { DetectAdblock } from '@scthakuri/adblock-detector'; // Keep DetectAdblock
import AdblockDetectionModal from '../AdblockDetectionModal'; // Import custom modal
import WelcomeModal from '../WelcomeModal'; // Import the new WelcomeModal

const MultiStreamViewer = () => {
  const isMobile = useMobileDetection();
  const [gridSize, setGridSize] = useState(isMobile ? 2 : 4);
  const [streams, setStreams] = useState(Array(12).fill(null));
  // apiUrl is no longer needed here as it's constructed in the hook
  const [isThumbnailGridExpanded, setIsThumbnailGridExpanded] = useState(false);
  const [autoReplace, setAutoReplace] = useState('0');
  const [showAdblockModal, setShowAdblockModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false); // State for welcome modal

  // State for new filters
  const [regionFilter, setRegionFilter] = useState('');
  const [hdFilter, setHdFilter] = useState(undefined); // Use undefined for 'any'
  const [exhibitionistFilter, setExhibitionistFilter] = useState(undefined); // Use undefined for 'any'

  // Create filters object for the hook
  const streamFilters = useMemo(() => ({
    // Add existing filters if they are managed here eventually (e.g., gender, tag, sort)
    // For now, just the new ones:
    region: regionFilter,
    hd: hdFilter,
    exhibitionist: exhibitionistFilter,
  }), [regionFilter, hdFilter, exhibitionistFilter]);

  // Call useStreams with filters object
  const { 
    availableStreams, 
    isLoading, 
    isLoadingMore, 
    hasMore, 
    loadMoreStreams
    // refreshStreams // Removed as it's currently unused
    // lastUpdated is removed from hook return, can be managed locally if needed
  } = useStreams(streamFilters); 

  const navigate = useNavigate();
  const location = useLocation();
  const isInitialMount = useRef(true);

  console.log('Render: MultiStreamViewer', { 
    gridSize, 
    streams, 
    availableStreamsCount: availableStreams.length,
    isLoading,
    isLoadingMore,
    hasMore,
    // lastUpdated: lastUpdated ? lastUpdated.toLocaleTimeString() : null, // Removed
    autoReplace, 
    location,
    streamFilters
  });

  const fillEmptySlots = useCallback((currentStreams, size, replaceMode) => {
    console.log('fillEmptySlots called', { currentStreams, size, replaceMode });
    let availableForReplacement = [...availableStreams];

    if (replaceMode === 'goal_reached') {
      availableForReplacement.sort((a, b) => (a.tokensLeft || Infinity) - (b.tokensLeft || Infinity));
    } else if (replaceMode === 'most_popular') {
      availableForReplacement.sort((a, b) => b.numUsers - a.numUsers);
    } else if (replaceMode === 'random') {
      availableForReplacement.sort(() => Math.random() - 0.5);
    }

    const newStreams = [...currentStreams];
    for (let i = 0; i < size; i++) {
      if (!newStreams[i]) {
        const replacement = availableForReplacement.find(s => !newStreams.includes(s));
        if (replacement) {
          newStreams[i] = replacement;
          availableForReplacement = availableForReplacement.filter(s => s !== replacement);
        }
      }
    }
    return newStreams;
  }, [availableStreams]);

  useEffect(() => {
    console.log('Initial mount effect', { isInitialMount: isInitialMount.current, availableStreamsLength: availableStreams.length });
    if (isInitialMount.current && availableStreams.length > 0) {
      const { usernames, gridSize: urlGridSize, autoReplace: urlAutoReplace } = parseUrl(location.pathname, location.search);
      console.log('Parsed URL', { usernames, urlGridSize, urlAutoReplace });
      
      setGridSize(urlGridSize);
      setAutoReplace(urlAutoReplace);

      let initialStreams = Array(12).fill(null);
      if (usernames.length > 0) {
        console.log('Loading streams from URL', usernames);
        usernames.forEach((username, index) => {
          const stream = availableStreams.find(s => s.id === username);
          console.log(`Searching for stream with id: ${username}`, stream ? 'Found' : 'Not found');
          if (stream && index < urlGridSize) {
            initialStreams[index] = stream;
          }
        });
      }

      if (urlAutoReplace !== '0') {
        console.log('Auto-replacing empty slots');
        initialStreams = fillEmptySlots(initialStreams, urlGridSize, urlAutoReplace);
      }

      console.log('Setting initial streams', initialStreams);
      setStreams(initialStreams);

      // Show welcome modal only if it's the initial mount AND no specific streams were requested via URL
      if (isInitialMount.current && usernames.length === 0) {
        console.log('Showing welcome modal');
        setShowWelcomeModal(true);
      }

      isInitialMount.current = false;
      console.log('Initial mount complete', { streams: initialStreams, urlGridSize, urlAutoReplace });
    }
  }, [location, availableStreams, fillEmptySlots]); // Keep dependencies as they are

  // Adblock detection effect
  useEffect(() => {
    DetectAdblock((detected) => {
      if (detected) {
        setShowAdblockModal(true);
      }
    });
  }, []);

  useEffect(() => {
    console.log('URL update effect', { isInitialMount: isInitialMount.current, streams, gridSize, autoReplace });
    if (!isInitialMount.current) {
      const selectedStreams = streams.filter(stream => stream !== null);
      const url = generateUrl(selectedStreams, gridSize, autoReplace);
      console.log('Generated URL', { url, currentLocation: location.pathname + location.search });
      if (url !== location.pathname + location.search) {
        console.log('Navigating to new URL', url);
        navigate(url, { replace: true });
      }
    }
  }, [streams, gridSize, autoReplace, navigate, location]);

  useEffect(() => {
    const maxGridSize = getMaxGridSize(isMobile);
    if (gridSize > maxGridSize) {
      setGridSize(maxGridSize);
    } else if (isMobile && gridSize > 4) {
      setGridSize(4);
    }
  }, [isMobile, gridSize]);

  const handleDragStart = useCallback((e, stream) => {
    console.log('handleDragStart called', { stream });
    e.dataTransfer.setData('application/json', JSON.stringify(stream));
    e.dataTransfer.effectAllowed = 'move';
    // Close the grid shortly after drag starts to reveal drop targets
    setTimeout(() => {
      setIsThumbnailGridExpanded(false);
    }, 10); // Small delay (10ms)
  }, []);

  const handleDragEnter = useCallback((index) => {
    console.log('handleDragEnter called', { index });
    // This function can be used for visual feedback during drag
  }, []);

  const handleDragLeave = useCallback((e) => {
    console.log('handleDragLeave called');
    // This function can be used for resetting visual feedback after drag
  }, []);

  const handleDrop = useCallback((index, e) => {
    console.log('handleDrop called', { index });
    try {
      // Log available data types for debugging
      console.log('DataTransfer available types:', Array.from(e.dataTransfer.types || []));
      
      // Check if dataTransfer is populated
      if (!e.dataTransfer || typeof e.dataTransfer.getData !== 'function') {
        console.error('DataTransfer object is invalid or unavailable', e.dataTransfer);
        return;
      }
      
      const droppedStreamData = e.dataTransfer.getData('application/json');
      console.log('Raw dropped data:', droppedStreamData ? droppedStreamData.substring(0, 100) + '...' : 'empty');
      
      // Check if we have data before trying to parse
      if (!droppedStreamData) {
        console.error('No dropped stream data received');
        return;
      }
      
      // Try to parse, with better error catching
      let droppedStream;
      try {
        droppedStream = JSON.parse(droppedStreamData);
      } catch (parseError) {
        console.error('JSON parse error:', parseError.message, '\nRaw data:', droppedStreamData);
        return;
      }
      
      // Validate the parsed object
      if (!droppedStream || typeof droppedStream !== 'object') {
        console.error('Invalid stream object after parsing:', droppedStream);
        return;
      }
      
      console.log('Successfully parsed stream data:', droppedStream);
      
      // Update streams state
      setStreams(prevStreams => {
        const updatedStreams = [...prevStreams];
        updatedStreams[index] = droppedStream;
        console.log('Streams updated after drop', updatedStreams);
        return updatedStreams;
      });
    } catch (error) {
      console.error('Error handling drop:', error.message || 'Unknown error');
      console.error('Error stack:', error.stack);
      console.error('Error details:', error);
    }
  }, []);

  const handleClose = useCallback((index) => {
    console.log('handleClose called', { index });
    setStreams(prevStreams => {
      const newStreams = [...prevStreams];
      newStreams[index] = null;
      console.log('Streams updated after close', newStreams);
      return newStreams;
    });
  }, []);

  const handleThumbnailClick = useCallback((stream) => {
    console.log('handleThumbnailClick called', { stream });
    setStreams(prevStreams => {
      const newStreams = [...prevStreams];
      const emptyIndex = newStreams.findIndex(s => s === null);
      
      if (emptyIndex !== -1 && emptyIndex < gridSize) {
        newStreams[emptyIndex] = stream;
      } else {
        newStreams[gridSize - 1] = stream;
      }
      
      console.log('Streams updated after thumbnail click', newStreams);
      return newStreams;
    });
    setIsThumbnailGridExpanded(false);
  }, [gridSize]);

  const handleEmptyClick = useCallback(() => {
    console.log('handleEmptyClick called');
    setIsThumbnailGridExpanded(true);
  }, []);

  const fillRandomStreams = useCallback(() => {
    console.log('fillRandomStreams called');
    if (availableStreams.length === 0) {
      console.warn('No available streams to select randomly');
      return;
    }

    // Create a copy of available streams and shuffle it
    const shuffledStreams = [...availableStreams].sort(() => Math.random() - 0.5);
    
    // Fill the grid with unique random streams
    const newStreams = Array(12).fill(null);
    for (let i = 0; i < gridSize; i++) {
      if (i < shuffledStreams.length) {
        newStreams[i] = shuffledStreams[i];
      }
    }
    
    console.log('Setting random streams', newStreams);
    setStreams(newStreams);
  }, [availableStreams, gridSize]);

  const handleRandomClick = useCallback((index) => {
    console.log('handleRandomClick called', { index });
    if (availableStreams.length === 0) {
      console.warn('No available streams to select randomly');
      return;
    }

    // Get a random stream that's not already in the grid
    const currentStreamsInGrid = streams.filter(s => s !== null);
    const availableForRandom = availableStreams.filter(s => !currentStreamsInGrid.some(cs => cs.id === s.id));
    
    if (availableForRandom.length === 0) {
      console.warn('All available streams are already in the grid');
      // Fall back to any random stream if all are already in the grid
      const randomIndex = Math.floor(Math.random() * availableStreams.length);
      const randomStream = availableStreams[randomIndex];
      
      setStreams(prevStreams => {
        const newStreams = [...prevStreams];
        newStreams[index] = randomStream;
        console.log('Streams updated with random stream (fallback)', newStreams);
        return newStreams;
      });
      return;
    }
    
    // Select a random stream from the available ones
    const randomIndex = Math.floor(Math.random() * availableForRandom.length);
    const randomStream = availableForRandom[randomIndex];
    
    setStreams(prevStreams => {
      const newStreams = [...prevStreams];
      newStreams[index] = randomStream;
      console.log('Streams updated with random stream', newStreams);
      return newStreams;
    });
  }, [availableStreams, streams]);

  const handleToggleThumbnailGrid = useCallback(() => {
    console.log('handleToggleThumbnailGrid called', { isThumbnailGridExpanded });
    setIsThumbnailGridExpanded(prev => !prev);
  }, [isThumbnailGridExpanded]);

  const selectedStreams = useMemo(() => streams.filter(stream => stream !== null), [streams]);

  return (
    <div className="flex flex-col h-screen">
      <HeaderBar 
        gridSize={gridSize} 
        setGridSize={setGridSize}
        selectedStreams={selectedStreams}
        autoReplace={autoReplace}
        setAutoReplace={setAutoReplace}
        onFillRandomStreams={fillRandomStreams}
        onToggleThumbnailGrid={handleToggleThumbnailGrid}
        isThumbnailGridExpanded={isThumbnailGridExpanded}
      />

      <div className="flex-grow overflow-hidden z-0">
        <div className={`grid ${getGridClassName(gridSize, isMobile)} h-full`}>
          {streams.slice(0, gridSize).map((stream, index) => (
            <GridCell
              key={index}
              index={index}
              stream={stream}
              onDrop={handleDrop}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onClose={handleClose}
              onEmptyClick={handleEmptyClick}
              onRandomClick={() => handleRandomClick(index)}
            />
          ))}
        </div>
      </div>

      <ThumbnailGrid
        availableStreams={availableStreams}
        onDragStart={handleDragStart}
        onClick={handleThumbnailClick}
        isExpanded={isThumbnailGridExpanded}
        onToggleExpand={handleToggleThumbnailGrid}
        onCollapse={() => setIsThumbnailGridExpanded(false)}
        onExpand={() => setIsThumbnailGridExpanded(true)}
        isLoading={isLoading}
        // Pass new filter state and setters
        regionFilter={regionFilter}
        setRegionFilter={setRegionFilter}
        hdFilter={hdFilter}
        setHdFilter={setHdFilter}
        exhibitionistFilter={exhibitionistFilter}
        setExhibitionistFilter={setExhibitionistFilter}
        // Pass pagination props
        loadMoreStreams={loadMoreStreams}
        hasMore={hasMore}
        isLoadingMore={isLoadingMore}
        // lastUpdated={lastUpdated} // Removed this line
      />
      <AdblockDetectionModal 
        isOpen={showAdblockModal} 
        onClose={() => setShowAdblockModal(false)} 
      />
      <WelcomeModal 
        isOpen={showWelcomeModal} 
        onClose={() => setShowWelcomeModal(false)} 
      />
    </div>
  );
};

export default MultiStreamViewer;
