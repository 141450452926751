import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import MultiStreamViewer from './components/MultiStreamViewer';

const MEASUREMENT_ID = "G-7TZD2DRD32"; 
ReactGA.initialize(MEASUREMENT_ID);

// Component to track page views
function RouteChangeTracker() {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with the full path including search params
    const pagePath = location.pathname + location.search;
    ReactGA.send({ hitType: "pageview", page: pagePath });
    console.log(`GA Pageview sent: ${pagePath}`);
  }, [location]);

  return null; // This component doesn't render anything
}

function App() {
  return (
    <Router>
      <RouteChangeTracker /> {/* Add tracker inside Router */}
      <div className="App">
        <Routes>
          <Route path="/" element={<MultiStreamViewer />} />
          <Route path="/view/*" element={<MultiStreamViewer />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
