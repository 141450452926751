import React, { useCallback, useState } from 'react';
import StreamPlayer from './StreamPlayer';
import { Shuffle } from 'lucide-react';

const GridCell = ({ index, stream, onDrop, onDragEnter, onDragLeave, onClose, onEmptyClick, onRandomClick }) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = 'move';
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    onDrop(index, e);
  }, [onDrop, index]);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
    onDragEnter(index);
  }, [onDragEnter, index]);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsDragging(false);
      onDragLeave(e);
    }
  }, [onDragLeave]);

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      className="relative w-full h-full overflow-hidden border border-gray-600"
    >
      <div className="w-full h-full relative">
        {stream ? (
          <StreamPlayer 
            stream={stream} 
            onClose={() => onClose(index)} 
            isDragging={isDragging} 
            onRandomClick={() => onRandomClick(index)}
          />
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center relative overflow-hidden">
            {/* Gradient background */}
            <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
              {/* Animated gradient overlay */}
              <div className="absolute inset-0 opacity-20 bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 animate-pulse bg-gradient-animate"></div>
              
              {/* Subtle grid pattern */}
              <div className="absolute inset-0 opacity-5" style={{ 
                backgroundImage: 'linear-gradient(#fff 1px, transparent 1px), linear-gradient(90deg, #fff 1px, transparent 1px)',
                backgroundSize: '20px 20px'
              }}></div>
            </div>
            
            {/* Main content - centered in the cell */}
            <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
              <div 
                className="flex flex-col items-center justify-center cursor-pointer hover:bg-gray-800/50 transition-all duration-200 p-5 rounded-xl backdrop-blur-sm"
                onClick={onEmptyClick}
              >
                <div className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-full p-3 mb-3 shadow-lg shadow-indigo-500/30">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                </div>
                <p className="text-white font-medium text-center">Click to browse cams</p>
                <p className="text-gray-300 text-sm text-center">or drop one here</p>
              </div>
            </div>
            
            {/* Random button - positioned at the bottom */}
            <div className="absolute bottom-4 left-0 right-0 flex justify-center z-10">
              <button
                onClick={onRandomClick}
                className="flex items-center bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white px-5 py-2 rounded-md transition-all duration-200 shadow-lg shadow-purple-500/30"
              >
                <Shuffle size={16} className="mr-2" />
                <span>I'm feeling lucky</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GridCell;
