import React from 'react';
import { X, Tv } from 'lucide-react'; // Using Tv icon like the header

const WelcomeModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4 backdrop-blur-sm">
      <div className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 rounded-lg shadow-xl p-6 md:p-8 max-w-lg w-full border border-indigo-500/30">
        {/* Header */}
        <div className="flex items-center justify-between mb-5">
           <div className="flex items-center space-x-3">
             <div className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-full p-2 shadow-lg shadow-indigo-500/20">
               <Tv className="w-5 h-5 text-white" />
             </div>
             <h1 className="text-2xl font-bold text-white" style={{ fontFamily: "'Orbitron', sans-serif" }}>Welcome to GridCams!</h1>
           </div>
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-white transition"
            aria-label="Close modal"
          >
            <X size={24} />
          </button>
        </div>

        {/* Content */}
        <div className="text-gray-300 space-y-4 mb-6">
          <p>
            Experience live streams like never before! GridCams lets you watch multiple cams simultaneously in a customizable grid.
          </p>
          <p>
            Drag and drop your favorite performers, adjust the layout, and enjoy a unique viewing experience.
          </p>
        </div>

        {/* Footer with CTA and Age Confirmation */}
        <div className="text-center">
           <button
            onClick={onClose}
            className="w-full sm:w-auto bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-semibold py-3 px-8 rounded-md transition-all duration-200 shadow-lg shadow-purple-500/30 text-lg mb-3"
          >
            Enter Site
          </button>
           <p className="text-xs text-gray-500">
            By entering, you confirm you are 18 years of age or older.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
