import React, { useState, useCallback, useRef } from 'react';
import { Search, X, Grid, LayoutGrid, SlidersHorizontal } from 'lucide-react'; // Added SlidersHorizontal
import StreamThumbnail from './StreamThumbnail';
import { useMobileDetection } from '../../hooks/useMediaQuery';

const ThumbnailGrid = ({ 
  availableStreams = [], // Provide a default empty array
  onDragStart, 
  onClick, 
  isExpanded, 
  onToggleExpand, 
  onCollapse, 
  onExpand,
  isLoading,
  // lastUpdated prop is no longer used here
  // New props for filters and pagination:
  regionFilter, setRegionFilter,
  hdFilter, setHdFilter,
  exhibitionistFilter, setExhibitionistFilter,
  loadMoreStreams,
  hasMore,
  isLoadingMore
}) => {
  const isMobile = useMobileDetection();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [sortOption, setSortOption] = useState('default');
  const [thumbnailSize, setThumbnailSize] = useState(isMobile ? 'medium' : 'small');
  const [showMobileFilters, setShowMobileFilters] = useState(false); // Renamed state
  const [showDesktopFilters, setShowDesktopFilters] = useState(false); 
  // Local state for UI controls - ideally lift this up later
  const [localRegion, setLocalRegion] = useState(regionFilter || ''); 
  const [localHd, setLocalHd] = useState(hdFilter === undefined ? '' : hdFilter.toString()); // Use string for select value
  const [localExhibitionist, setLocalExhibitionist] = useState(exhibitionistFilter === undefined ? '' : exhibitionistFilter.toString()); // Use string for select value
  
  const gridRef = useRef(null);
  const headerRef = useRef(null);
  const searchRef = useRef(null);

  const allTags = React.useMemo(() => {
    const tagSet = new Set();
    availableStreams.forEach(stream => {
      stream.tags?.forEach(tag => tagSet.add(tag));
    });
    return Array.from(tagSet).sort();
  }, [availableStreams]);

  const filteredAndSortedStreams = React.useMemo(() => {
    if (!Array.isArray(availableStreams)) {
      console.error('availableStreams is not an array:', availableStreams);
      return [];
    }

    let result = availableStreams.filter(stream => {
      const searchMatch = searchTerm.length < 2 || 
        stream.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        stream.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase())) ||
        stream.roomSubject?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const genderMatch = selectedGender === '' || stream.gender === selectedGender;
      const tagMatch = selectedTag === '' || stream.tags?.includes(selectedTag);
      
      return searchMatch && genderMatch && tagMatch;
    });

    switch (sortOption) {
      case 'mostPopular':
        result.sort((a, b) => (b.numUsers || 0) - (a.numUsers || 0));
        break;
      case 'goalReached':
        result.sort((a, b) => {
          if (a.tokensLeft === null && b.tokensLeft === null) return 0;
          if (a.tokensLeft === null) return 1;
          if (b.tokensLeft === null) return -1;
          return a.tokensLeft - b.tokensLeft;
        });
        break;
      default:
        // 'default' sorting, do nothing
        break;
    }

    return result;
  }, [availableStreams, searchTerm, selectedGender, selectedTag, sortOption]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handleGenderChange = (e) => {
    setSelectedGender(e.target.value);
  };

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };
  
  const handleThumbnailSizeChange = (e) => {
    setThumbnailSize(e.target.value);
  };

  const handleThumbnailClick = useCallback((stream) => {
    onClick(stream);
  }, [onClick]);

  const handleDragStart = useCallback((e, stream) => {
    onDragStart(e, stream);
  }, [onDragStart]);

  return (
    <>
      {/* Floating button to open the grid - only shown on desktop */}
      {!isMobile && (
        <button
          onClick={onToggleExpand}
          className="fixed bottom-4 right-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white p-3 rounded-full shadow-lg z-50 hover:from-indigo-700 hover:to-purple-700 transition-all duration-200"
        >
          <Grid size={20} className="text-white" />
        </button>
      )}
      
      {/* Modal overlay */}
      {isExpanded && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-80 flex items-center justify-center z-50 backdrop-blur-sm transition-opacity duration-200">
          {/* Adjusted modal container for more space on desktop */}
          <div 
            ref={gridRef}
            className={`relative flex flex-col overflow-hidden ${
              isMobile ? 'h-full w-full m-0' : 'h-full w-full p-8 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 rounded-lg border border-gray-700 shadow-xl' // Use padding, remove fixed h/w
            }`}
          >
            <div 
              ref={headerRef}
              className="w-full bg-gradient-to-r from-gray-800 to-gray-700 flex items-center justify-between px-3 md:px-6 relative z-10 border-b border-gray-700"
              style={{ height: isMobile ? '64px' : '64px' }}
            >
              {/* Subtle animated gradient overlay for header - make sure it doesn't block interactions */}
              <div className="absolute inset-0 opacity-10 bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 bg-gradient-animate pointer-events-none"></div>
              
              <div className="flex items-center z-10">
                <h2 className="text-white text-xl font-bold">Cams</h2> {/* Changed title and size */}
                {isLoading && (
                  <div className="ml-3 text-xs text-gray-300 flex items-center">
                    <div className="animate-spin h-3 w-3 border-2 border-indigo-500 border-t-transparent rounded-full mr-1"></div>
                    Refreshing...
                  </div>
                )}
                {/* Removed Last Updated Timestamp */}
              </div>

              {/* Mobile header controls */}
              {isMobile && (
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => setShowMobileFilters(!showMobileFilters)} // Use renamed state setter
                    className="text-white p-2 rounded-md bg-gray-700 hover:bg-gray-600"
                  >
                    <Search size={18} /> {/* This button now toggles the filter section */}
                  </button>
                  <button
                    onClick={onCollapse}
                    className="text-white p-2 rounded-md bg-gray-800 hover:bg-gray-700"
                  >
                    <X size={18} />
                  </button>
                </div>
              )}

              {/* Desktop header controls */}
              {!isMobile && (
                <div className="flex items-center space-x-3">
                  {/* Keep Thumbnail Size Selector */}
                  <div className="flex items-center">
                    <LayoutGrid size={18} className="text-gray-400 mr-1" />
                    <select
                      value={thumbnailSize}
                      onChange={handleThumbnailSizeChange}
                      className="bg-gray-700 text-white text-sm p-1.5 rounded-md focus:outline-none border border-gray-600 focus:border-indigo-500"
                    >
                      <option value="small">Small</option>
                      <option value="medium">Medium</option>
                      <option value="large">Large</option>
                    </select>
                  </div>
                  {/* Keep Search Bar */}
                  <div ref={searchRef} className="flex items-center bg-gray-700 rounded-md border border-gray-600">
                    <Search size={18} className="text-gray-400 ml-2" />
                    <input
                      type="text"
                      placeholder="Search profiles, tags, subjects..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="bg-transparent text-white text-sm p-1.5 w-56 focus:outline-none"
                    />
                    {searchTerm && (
                      <button
                        onClick={clearSearch}
                        className="text-gray-400 hover:text-white p-1"
                      >
                        <X size={18} />
                      </button>
                    )}
                  </div>
                  {/* Add Filter Toggle Button */}
                  <button
                    onClick={() => setShowDesktopFilters(!showDesktopFilters)}
                    className={`flex items-center text-sm p-1.5 rounded-md border border-gray-600 transition-colors ${showDesktopFilters ? 'bg-indigo-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'}`}
                  >
                    <SlidersHorizontal size={16} className="mr-1" />
                    Filters
                  </button>
                  {/* Keep Close Button */}
                  <button
                    onClick={onCollapse}
                    className="ml-2 bg-gradient-to-r from-gray-700 to-gray-600 hover:from-gray-600 hover:to-gray-500 text-white p-1.5 rounded-md transition-all duration-200 border border-gray-600"
                  >
                    <X size={18} />
                  </button>
                </div>
              )}
            </div>

            {/* Desktop Filters Section - Collapsible */}
            {!isMobile && showDesktopFilters && (
              <div className="bg-gray-800 p-3 border-b border-gray-700 flex items-center space-x-3">
                 <select
                    value={selectedGender}
                    onChange={handleGenderChange}
                    className="bg-gray-700 text-white text-sm p-1.5 rounded-md focus:outline-none border border-gray-600 focus:border-indigo-500"
                  >
                    <option value="">All Genders</option>
                    <option value="Women">Women</option>
                    <option value="Men">Men</option>
                    <option value="Couples">Couples</option>
                    <option value="Trans">Trans</option>
                  </select>
                  <select
                    value={selectedTag}
                    onChange={handleTagChange}
                    className="bg-gray-700 text-white text-sm p-1.5 rounded-md focus:outline-none border border-gray-600 focus:border-indigo-500"
                  >
                    <option value="">All Tags</option>
                    {allTags.map(tag => (
                      <option key={tag} value={tag}>{tag}</option>
                    ))}
                  </select>
                  <select
                    value={sortOption}
                    onChange={handleSortChange}
                    className="bg-gray-700 text-white text-sm p-1.5 rounded-md focus:outline-none border border-gray-600 focus:border-indigo-500"
                  >
                    <option value="default">Default Order</option>
                    <option value="mostPopular">Most Popular Rooms</option>
                    <option value="goalReached">Goal Reached!</option>
                  </select>
                  {/* Region Filter */}
                  <select
                    value={localRegion}
                    onChange={(e) => { setLocalRegion(e.target.value); setRegionFilter(e.target.value); }}
                    className="bg-gray-700 text-white text-sm p-1.5 rounded-md focus:outline-none border border-gray-600 focus:border-indigo-500"
                  >
                    <option value="">All Regions</option>
                    <option value="asia">Asia</option>
                    <option value="europe_russia">Europe/Russia</option>
                    <option value="northamerica">North America</option>
                    <option value="southamerica">South America</option>
                    <option value="other">Other</option>
                  </select>
                  {/* HD Filter */}
                  <select
                    value={localHd}
                    onChange={(e) => { 
                      const val = e.target.value;
                      setLocalHd(val); 
                      setHdFilter(val === '' ? undefined : val === 'true'); 
                    }}
                    className="bg-gray-700 text-white text-sm p-1.5 rounded-md focus:outline-none border border-gray-600 focus:border-indigo-500"
                  >
                    <option value="">Any Quality</option>
                    <option value="true">HD Only</option>
                    <option value="false">SD Only</option>
                  </select>
                  {/* Exhibitionist Filter */}
                   <select
                    value={localExhibitionist}
                     onChange={(e) => { 
                      const val = e.target.value;
                      setLocalExhibitionist(val); 
                      setExhibitionistFilter(val === '' ? undefined : val === 'true'); 
                    }}
                    className="bg-gray-700 text-white text-sm p-1.5 rounded-md focus:outline-none border border-gray-600 focus:border-indigo-500"
                  >
                    <option value="">Any Type</option>
                    <option value="true">Exhibitionist</option>
                    <option value="false">Non-Exhibitionist</option>
                  </select>
              </div>
            )}
            
            {/* Mobile search filters - collapsible */}
            {isMobile && showMobileFilters && ( // Use renamed state variable
              <div className="bg-gray-800 bg-opacity-90 p-3 border-b border-gray-700 space-y-3"> {/* Added space-y */}
                {/* Search Bar */}
                <div className="flex items-center bg-gray-700 rounded-md border border-gray-600">
                  <Search size={18} className="text-gray-400 ml-2" />
                  <input
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="bg-transparent text-white text-sm p-1.5 w-full focus:outline-none"
                  />
                  {searchTerm && (
                    <button
                      onClick={clearSearch}
                      className="text-gray-400 hover:text-white p-1"
                    >
                      <X size={18} />
                    </button>
                  )}
                </div>
                
                <div className="grid grid-cols-2 gap-2">
                  <select
                    value={selectedGender}
                    onChange={handleGenderChange}
                    className="bg-gray-700 text-white text-sm p-2 rounded-md focus:outline-none border border-gray-600"
                  >
                    <option value="">All Genders</option>
                    <option value="Women">Women</option>
                    <option value="Men">Men</option>
                    <option value="Couples">Couples</option>
                    <option value="Trans">Trans</option>
                  </select>
                  <select
                    value={sortOption}
                    onChange={handleSortChange}
                    className="bg-gray-700 text-white text-sm p-2 rounded-md focus:outline-none border border-gray-600"
                  >
                    <option value="default">Default Order</option>
                    <option value="mostPopular">Most Popular</option>
                    <option value="goalReached">Goal Reached</option>
                  </select>
                  {/* Add new mobile filters */}
                   <select
                    value={localRegion}
                    onChange={(e) => { setLocalRegion(e.target.value); setRegionFilter(e.target.value); }}
                    className="bg-gray-700 text-white text-sm p-2 rounded-md focus:outline-none border border-gray-600"
                  >
                    <option value="">All Regions</option>
                    <option value="asia">Asia</option>
                    <option value="europe_russia">Europe/Russia</option>
                    <option value="northamerica">North America</option>
                    <option value="southamerica">South America</option>
                    <option value="other">Other</option>
                  </select>
                   <select
                    value={localHd}
                    onChange={(e) => { 
                      const val = e.target.value;
                      setLocalHd(val); 
                      setHdFilter(val === '' ? undefined : val === 'true'); 
                    }}
                    className="bg-gray-700 text-white text-sm p-2 rounded-md focus:outline-none border border-gray-600"
                  >
                    <option value="">Any Quality</option>
                    <option value="true">HD Only</option>
                    <option value="false">SD Only</option>
                  </select>
                   <select
                    value={localExhibitionist}
                     onChange={(e) => { 
                      const val = e.target.value;
                      setLocalExhibitionist(val); 
                      setExhibitionistFilter(val === '' ? undefined : val === 'true'); 
                    }}
                    className="bg-gray-700 text-white text-sm p-2 rounded-md focus:outline-none border border-gray-600"
                  >
                    <option value="">Any Type</option>
                    <option value="true">Exhibitionist</option>
                    <option value="false">Non-Exhibitionist</option>
                  </select>
                </div>
              </div>
            )}
            
            {/* Content area */}
            <div className={`overflow-y-auto flex-grow relative z-10 ${isMobile ? 'p-2' : 'p-4'}`}>
              {filteredAndSortedStreams.length > 0 ? (
                <div 
                  className="grid gap-2 md:gap-4 auto-rows-fr" 
                  style={{ 
                    gridTemplateColumns: `repeat(auto-fill, minmax(${
                      thumbnailSize === 'small' ? '140px' : 
                      thumbnailSize === 'medium' ? '180px' : '240px'
                    }, 1fr))`
                  }}
                >
                  {filteredAndSortedStreams.map((stream) => (
                    <StreamThumbnail 
                      key={stream.id} 
                      stream={stream} 
                      onDragStart={(e) => handleDragStart(e, stream)}
                      onClick={() => handleThumbnailClick(stream)}
                      showHoverEffects={true}
                    />
                  ))}
                </div>
              ) : (
                <div className="flex items-center justify-center h-full">
                  <p className="text-white text-lg">No streams available</p>
                </div>
              )}
              {/* Load More Button */}
              {hasMore && (
                <div className="flex justify-center mt-4">
                  <button
                    onClick={loadMoreStreams}
                    disabled={isLoadingMore}
                    className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-6 rounded-md transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    {isLoadingMore ? 'Loading...' : 'Load More Cams'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ThumbnailGrid;
