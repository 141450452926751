import { useState, useEffect } from 'react';

/**
 * Hook that returns true if the current viewport matches the provided media query
 * @param {string} query - Media query string to match
 * @returns {boolean} - Whether the media query matches
 */
const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    
    // Update the state initially
    setMatches(media.matches);
    
    // Define listener function to update state on change
    const listener = (event) => {
      setMatches(event.matches);
    };
    
    // Add the listener
    media.addEventListener('change', listener);
    
    // Clean up
    return () => {
      media.removeEventListener('change', listener);
    };
  }, [query]);

  return matches;
};

/**
 * Hook that returns true if the current viewport is a mobile device
 * @returns {boolean} - Whether the current device is mobile
 */
export const useMobileDetection = () => {
  return useMediaQuery('(max-width: 768px)');
};

export default useMediaQuery;
