import React from 'react';
import { Users, Target } from 'lucide-react';

const StreamThumbnail = ({ stream, onClick, onDragStart, showHoverEffects = true, className = '' }) => {
  const baseClasses = "relative w-full aspect-video overflow-hidden rounded-lg shadow-lg";
  const hoverClasses = showHoverEffects ? "group cursor-pointer hover:ring-2 hover:ring-blue-500 transition-all duration-200" : "";

  return (
    <div 
      className={`${baseClasses} ${hoverClasses} ${className}`}
      onClick={onClick}
      onDragStart={onDragStart}
      draggable={!!onDragStart}
    >
      <img 
        src={stream.thumbnail} 
        alt={stream.title} 
        className="w-full h-full object-cover"
      />
      <div className={`absolute inset-0 bg-black ${showHoverEffects ? 'bg-opacity-0 group-hover:bg-opacity-50' : 'bg-opacity-50'} transition-opacity duration-200`}>
        <div className="absolute top-0 left-0 right-0 p-1.5 flex justify-between items-center text-sm text-white">
          <div className="flex items-center bg-black bg-opacity-60 rounded px-1.5 py-0.5">
            <Users size={14} className="mr-1" />
            <span>{stream.numUsers}</span>
          </div>
          {stream.tokensLeft !== null && (
            <div className={`flex items-center rounded px-1.5 py-0.5 ${
              stream.tokensLeft === 0 
                ? 'bg-green-500 text-white font-bold' 
                : 'bg-black bg-opacity-60'
            }`}>
              <Target size={14} className="mr-1" />
              <span>
                {stream.tokensLeft === 0 
                  ? 'Goal!' 
                  : stream.tokensLeft
                }
              </span>
            </div>
          )}
        </div>
        <div className={`absolute bottom-0 left-0 right-0 bg-black bg-opacity-75 text-white text-sm p-2 ${showHoverEffects ? 'opacity-0 group-hover:opacity-100' : 'opacity-100'} transition-opacity duration-200`}>
          <p className="truncate">{stream.title}</p>
        </div>
      </div>
    </div>
  );
};

export default StreamThumbnail;
