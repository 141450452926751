import React, { useState } from 'react';
import { Tv, Layers, Shuffle, Menu, X, Grid } from 'lucide-react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { useMobileDetection } from '../../hooks/useMediaQuery';
import { getGridSizeOptions } from '../../utils/gridUtils';

const HeaderBar = ({ gridSize, setGridSize, selectedStreams, autoReplace, setAutoReplace, onFillRandomStreams, onToggleThumbnailGrid, isThumbnailGridExpanded }) => {
  const isMobile = useMobileDetection();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const gridSizeOptions = getGridSizeOptions(isMobile);

  return (
    <>
      <div className="flex justify-between items-center p-3 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white relative overflow-hidden">
        {/* Subtle animated gradient overlay */}
        <div className="absolute inset-0 opacity-10 bg-gradient-to-r from-purple-500 via-indigo-500 to-blue-500 bg-gradient-animate pointer-events-none"></div>
        
        {/* Logo and title */}
        <div className="flex items-center space-x-3 z-10">
          <div className="bg-gradient-to-r from-indigo-500 to-purple-600 rounded-full p-2 shadow-lg shadow-indigo-500/20">
            <Tv className="w-5 h-5 text-white" />
          </div>
          <h1 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-gray-300" style={{ fontFamily: "'Orbitron', sans-serif" }}>GridCams</h1>
        </div>

        {/* Desktop navigation */}
        {!isMobile && (
          <div className="flex items-center space-x-3 z-10">
            <button
              onClick={onFillRandomStreams}
              className="inline-flex items-center justify-center rounded-md px-3 py-1.5 text-sm font-medium bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-700 hover:to-indigo-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 shadow-md transition-all duration-200"
            >
              <Shuffle className="mr-2 h-4 w-4 text-white" />
              <span>I'm feeling Lucky!</span>
            </button>
            
            <DropdownMenuPrimitive.Root>
              <DropdownMenuPrimitive.Trigger asChild>
                <button className="inline-flex items-center justify-center rounded-md px-3 py-1.5 text-sm font-medium bg-gradient-to-r from-gray-800 to-gray-700 text-white hover:from-gray-700 hover:to-gray-600 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 shadow-md">
                  <Layers className="mr-2 h-4 w-4 text-indigo-300" />
                  <span>{gridSize} Cams</span>
                </button>
              </DropdownMenuPrimitive.Trigger>
              <DropdownMenuPrimitive.Portal>
                <DropdownMenuPrimitive.Content className="min-w-[140px] bg-gray-800 rounded-md shadow-lg p-1.5 z-50 border border-gray-700">
                  {gridSizeOptions.map((option) => (
                    <DropdownMenuPrimitive.Item
                      key={option.value}
                      className="group text-sm rounded-md flex items-center px-3 py-1.5 cursor-pointer outline-none text-gray-200 hover:bg-gray-700 focus:bg-indigo-600 focus:text-white"
                      onSelect={() => setGridSize(option.value)}
                    >
                      {option.label} Cams
                    </DropdownMenuPrimitive.Item>
                  ))}
                </DropdownMenuPrimitive.Content>
              </DropdownMenuPrimitive.Portal>
            </DropdownMenuPrimitive.Root>
          </div>
        )}

        {/* Mobile controls */}
        {isMobile && (
          <div className="flex items-center space-x-2 z-10">
            {/* Thumbnail grid toggle button */}
            <button 
              onClick={onToggleThumbnailGrid}
              className={`p-2 rounded-md ${
                isThumbnailGridExpanded 
                  ? 'bg-indigo-600 text-white' 
                  : 'bg-gray-800 text-gray-300 hover:bg-gray-700'
              }`}
            >
              <Grid size={20} />
            </button>
            
            {/* Hamburger menu button */}
            <button 
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="p-2 text-white bg-gray-800 rounded-md hover:bg-gray-700"
            >
              {mobileMenuOpen ? <X size={20} /> : <Menu size={20} />}
            </button>
          </div>
        )}
      </div>

      {/* Mobile menu overlay */}
      {isMobile && mobileMenuOpen && (
        <div className="fixed inset-0 z-40 bg-gray-900 bg-opacity-80 backdrop-blur-sm transition-opacity duration-200">
          <div className="flex flex-col h-full p-5 pt-16 relative">
            {/* Close button at top right */}
            <button
              onClick={() => setMobileMenuOpen(false)}
              className="absolute top-4 right-4 p-2 bg-gray-800 text-white rounded-full hover:bg-gray-700 transition-colors"
            >
              <X size={20} />
            </button>
            
            <div className="flex flex-col space-y-4">
              <button
                onClick={() => {
                  onFillRandomStreams();
                  setMobileMenuOpen(false);
                }}
                className="w-full inline-flex items-center justify-center rounded-md px-4 py-2 text-base font-medium bg-gradient-to-r from-purple-600 to-indigo-600 text-white hover:from-purple-700 hover:to-indigo-700 focus:outline-none shadow-md transition-all duration-200"
              >
                <Shuffle className="mr-3 h-5 w-5 text-white" />
                <span>I'm feeling Lucky!</span>
              </button>
              
              <div className="w-full bg-gray-800 rounded-md p-3">
                <h3 className="text-white text-sm font-medium mb-3 flex items-center">
                  <Layers className="mr-2 h-4 w-4 text-indigo-300" />
                  Grid Size
                </h3>
                <div className="grid grid-cols-2 gap-2">
                  {gridSizeOptions.map((option) => (
                    <button
                      key={option.value}
                      className={`py-2 px-4 rounded-md text-center ${
                        gridSize === option.value 
                          ? 'bg-indigo-600 text-white' 
                          : 'bg-gray-700 text-gray-200 hover:bg-gray-600'
                      }`}
                      onClick={() => {
                        setGridSize(option.value);
                        setMobileMenuOpen(false);
                      }}
                    >
                      {option.label} Cams
                    </button>
                  ))}
                </div>
              </div>
              
              <button
                onClick={() => {
                  setMobileMenuOpen(false);
                  onToggleThumbnailGrid();
                }}
                className="w-full inline-flex items-center justify-center rounded-md px-4 py-2 text-base font-medium bg-gray-700 text-white hover:bg-gray-600 focus:outline-none shadow-md transition-all duration-200"
              >
                <Grid className="mr-3 h-5 w-5 text-indigo-300" />
                <span>Browse Streams</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderBar;
