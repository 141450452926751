import React from 'react';
import { X, AlertTriangle } from 'lucide-react'; // Using AlertTriangle for warning

// Re-using the generic Modal structure if available, assuming it handles basic modal logic (like background overlay, centering)
// If './MultiStreamViewer/Modal' doesn't exist or isn't suitable, this structure needs adjustment.
// For now, assuming a basic modal structure is available or we create one inline.
// Let's create a basic inline modal structure for clarity.

const AdblockDetectionModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="bg-gray-800 rounded-lg shadow-xl p-6 max-w-md w-full border border-yellow-500/50">
        {/* Header with Icon and Title */}
        <div className="flex items-center mb-4">
          <AlertTriangle className="text-yellow-400 mr-3 h-6 w-6" />
          <h2 className="text-xl font-semibold text-white">Ad Blocker Detected</h2>
          <button 
            onClick={onClose} 
            className="ml-auto text-gray-400 hover:text-white transition"
            aria-label="Close modal"
          >
            <X size={24} />
          </button>
        </div>

        {/* Content */}
        <div className="text-gray-300 space-y-4">
          <p>
            We've detected an ad blocker or content blocker is active in your browser.
          </p>
          <p>
            GridCams shows live streams from other websites. Ad blockers can prevent these streams from loading correctly, which stops the site from working as intended.
          </p>
          <p>
            To watch streams on GridCams, please disable your ad blocker for this site and refresh the page.
          </p>
          
          {/* Instructions (Optional but helpful) */}
          <details className="bg-gray-700/50 rounded p-3 text-sm">
             <summary className="cursor-pointer font-medium text-gray-200 hover:text-white">How to disable (click to expand)</summary>
             <ol className="list-decimal list-inside mt-2 space-y-1 pl-4">
               <li>Find the ad blocker icon in your browser's toolbar.</li>
               <li>Click the icon and look for an option like "Pause on this site", "Don't run on pages on this domain", or similar.</li>
               <li>Select that option to whitelist this site.</li>
               <li>You may need to refresh the page for the changes to take effect.</li>
             </ol>
          </details>
        </div>

        {/* Footer with Buttons */}
        <div className="flex justify-end space-x-3 mt-6">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-600 text-gray-300 rounded hover:bg-gray-700 transition"
          >
            Dismiss
          </button>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-yellow-500 text-gray-900 font-semibold rounded hover:bg-yellow-400 transition"
          >
            Refresh Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdblockDetectionModal;
