export const getGridClassName = (gridSize, isMobile = false) => {
  // For mobile devices, always display in a single column regardless of grid size
  if (isMobile) {
    return 'grid-cols-1';
  }

  // For desktop/tablet devices, use the standard grid layout
  switch (gridSize) {
    case 1: return 'grid-cols-1';
    case 2: return 'grid-cols-2';
    case 4: return 'grid-cols-2';
    case 6: return 'grid-cols-3';
    case 9: return 'grid-cols-3';
    case 12: return 'grid-cols-4';
    default: return 'grid-cols-2';
  }
};

// Get the maximum grid size based on device type
export const getMaxGridSize = (isMobile = false) => {
  return isMobile ? 4 : 12;
};

// Adjust gridSize options based on device type
export const getGridSizeOptions = (isMobile = false) => {
  if (isMobile) {
    return [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
    ];
  }
  
  return [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 4, label: '4' },
    { value: 6, label: '6' },
    { value: 9, label: '9' },
    { value: 12, label: '12' },
  ];
};